<template>
  <div>You will automatically be redirected</div>
</template>

<script>
import {defineComponent, onMounted} from 'vue';
import auth from "@fsinfuhh/js_auth_mafiasi";
import {useRouter} from "vue-router";
import {client} from "@/main"

export default defineComponent({
  setup() {
    const OpenidConfig = {
      issuer: "https://identity.mafiasi.de/realms/mafiasi",
      clientId: client
    }
    const router = useRouter()
    onMounted(() => {
      auth.doAuthorizationCallback(OpenidConfig).then(() => {
        router.push({name: "Index"})
      })
    })
  }
})
</script>
