<template>
  <div class="eventcard">
    <b style="grid-column: 1 / 4">Event erstellen</b>
    <button @click="onClose" class="closeButton">x</button>
    <div class="grid">
    <label>Name:</label>
    <input v-model="name" placeholder="Name"/>

    <label>
      Wochentag:
    </label>
    <select v-model="day">
      <option disabled></option>
      <option v-for="i in days" :key=i :value=i>{{ i.tag }}</option>
    </select>

    <label>Startwoche:</label>
    <input v-model="start_week" max="52" min="1" placeholder="Startwoche" type="number"/>
    <label>Endwoche:</label>
    <input v-model="end_week" max="52" min="1" placeholder="Endwoche" type="number"/>

    <div v-if="start_week > end_week" class="warning">
      Startwoche muss vor Endwoche liegen!
    </div>
    <label>Startzeit:</label>
    <input v-model="startzeit" type="time"/>

    <label>Endzeit:</label>
    <input v-model="endzeit" type="time"/>
    <div v-if="startzeit > endzeit" class="warning">
      Startzeit muss vor Endzeit liegen!
    </div>
    <label>Raum:</label>
    <input v-model="room" placeholder="Raum"/>
    <label>
      Farbe:
    </label>
    <select v-model="colour">
      <option disabled></option>
      <option v-for="i in colours" :key=i>{{ i }}</option>
    </select>
      <button
          :disabled='start_week > end_week || startzeit > endzeit || name.length === 0 || startzeit === "" || endzeit === "" || start_week === "" || end_week === "" || day === 0'
          @click="onSubmit"
          style="grid-column: 2; width: 10em">
        hinzufügen
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EigenesEvent",
  data() {
    return {
      name: "",
      days: [
        {
          id: 0,
          tag: "Montag"
        },
        {
          id: 1,
          tag: "Dienstag"
        },
        {
          id: 2,
          tag: "Mittwoch"
        },
        {
          id: 3,
          tag: "Donnerstag"
        },
        {
          id: 4,
          tag: "Freitag"
        }
      ],
      day: 0,
      start_week: "",
      end_week: "",
      startzeit: "",
      endzeit: "",
      room: "",
      colours: ["yellow", "lightgreen", "blueviolet", "coral", "darkgrey", "gold", "tomato", "springgreen", "skyblue"],
      colour: "coral"
    }
  },
  methods: {
    onSubmit() {
      let ds = new Date("2020-01-01T" + this.startzeit);
      let de = new Date("2020-01-01T" + this.endzeit);
      let startt = Math.floor((((ds.getHours() - 7) * 60) + ds.getMinutes()) / 5) + 1
      let endt = Math.floor((((de.getHours() - 7) * 60) + de.getMinutes()) / 5) + 1
      let len = endt - startt
      let eves = []
      let room;
      if (this.room === "") {
        room = "N.N."
      } else {
        room = this.room
      }
      for (let week of Array(Number(this.end_week) - Number(this.start_week) + 1).keys()) {
        let wee = week + Number(this.start_week)
        let eve = {
          name: this.name,
          weekday: this.day.id,
          week: wee,
          start: startt,
          end: endt,
          span: len,
          room: room,
          colour: this.colour
        }
        eves.push(eve)
      }
      this.$emit('submitted', eves);
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.eventcard {
  text-align: initial;
  width: 30em;
  box-shadow: 5px 5px 10px 5px #adbac6;
  padding: 25px;
  border-radius: 1em;
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
}

.eventcard label {
  grid-column: 1;
  text-align: right;
}

.eventcard input {
  grid-column: 2 / 4;
}

.eventcard .warning {
  color: red;
  grid-column: 1 / 4;
}

.closeButton {
  border: none;
  background-color: unset;
  font-size: larger;
  text-shadow: 4px 4px 3px #686868;
  position: absolute;
  right: 5px;
  top: 5px;
}

.closeButton:active {
  text-shadow: unset;
  translate: 2px 2px;
}
</style>