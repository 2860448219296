<template>
  <div class="modulauswahl">
    <span class="überschrift">nähere Auswahl:</span>
      <div v-for="course in courses" :key="course.id">
        <button @click="lastClicked(course)" :disabled="selected && course.id === selected.id && !testDisabled">
        {{ course.course_identifier }}
        </button>
      </div>
    <button style="grid-column: 1" @click="onClick" :disabled="testDisabled">+</button>
  </div>
</template>

<script>
import { eigenesEvent } from "@/courses"

export default {
  name: "Modulauswahl.vue",
  props: {courses: Array,
  selected: Object},
  computed: {
    testDisabled() {
      return eigenesEvent.value === true
    }
  },
  methods: {
    lastClicked( course ) {
      this.$emit("clicked", course.id)
      eigenesEvent.value = false
    },
    onClick() {
      eigenesEvent.value = true
    },
  }
}
</script>

<style scoped>
.modulauswahl {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.modulauswahl button {
  width: 100%;
  border: none;
  background-color: #fafafa;
  padding: 6px;
  box-shadow: 2px 3px 3px 0px #797979;
  font-size: large;
  border-radius: 4px;
}
.modulauswahl button:disabled {
  background-color: #2c3b90;
  color: white;
}
.modulauswahl .überschrift {
  font-weight: bold;
  font-size: large;
}
</style>