import {
    AuthorizationNotifier,
    AuthorizationRequest,
    AuthorizationServiceConfiguration,
    BaseTokenRequestHandler,
    BasicQueryStringUtils,
    DefaultCrypto,
    FetchRequestor,
    GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN,
    LocalStorageBackend,
    RedirectRequestHandler,
    TokenRequest,
} from "@openid/appauth"
import { decodeToken } from "jwt-js"

const KEY_ISSUER_CONFIG = "oidcConfiguration"
const KEY_ACCESS_TOKEN = "oidcAccessToken"
const KEY_REFRESH_TOKEN = "oidcRefreshToken"
const KEY_ID_TOKEN = "oidcIdToken"


export async function getAccessToken() {
    const token = sessionStorage.getItem(KEY_ACCESS_TOKEN)
    if (token != null) {
        const { payload } = decodeToken(token)
        if (isTokenExpired(payload)) {
            await renewTokens()
            return getAccessToken()
        } else {
            return token
        }
    }
}

export async function getIdToken() {
    const token = sessionStorage.getItem(KEY_ID_TOKEN)
    if (token != null) {
        const { payload } = decodeToken(token)
        if (isTokenExpired(payload)) {
            await renewTokens()
            return getIdToken()
        } else {
            return payload
        }
    }
}

export async function isAuthenticated() {
    return await getAccessToken() != null
}

export async function doAuthorization(callbackUri) {
    const config = await getServiceConfiguration()

    const request = new AuthorizationRequest({
        client_id: process.env.VUE_APP_OPENID_CLIENT_ID,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        redirect_uri: callbackUri,
        scope: "openid"
    }, new DefaultCrypto(), true)

    const authorizationHandler = new RedirectRequestHandler()
    authorizationHandler.performAuthorizationRequest(config, request)
}

export async function doAuthorizationCallback() {
    return new Promise(((resolve, reject) => {
        const notifier = new AuthorizationNotifier()
        notifier.setAuthorizationListener(((request, response, error) => {
            if (error != null) {
                reject(error)
            } else {
                resolve(exchangeCodeForTokens(response.code, request))
            }
        }))

        const authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new BasicQueryStringUtils(), {
            ...window.location,
            hash: window.location.search
        })
        authorizationHandler.setAuthorizationNotifier(notifier)
        authorizationHandler.completeAuthorizationRequestIfPossible().then()
    }))
}

export default {
    getAccessToken,
    getIdToken,
    isAuthenticated,
    doAuthorization,
    doAuthorizationCallback
}

async function exchangeCodeForTokens(code, authorizationRequest) {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
    const request = new TokenRequest({
        client_id: process.env.VUE_APP_OPENID_CLIENT_ID,
        redirect_uri: authorizationRequest.redirectUri,
        grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
        code,
        extras: {
            code_verifier: authorizationRequest.internal["code_verifier"]
        }
    })
    const response = await tokenHandler.performTokenRequest(await getServiceConfiguration(), request)
    sessionStorage.setItem(KEY_ID_TOKEN, response.idToken)
    sessionStorage.setItem(KEY_ACCESS_TOKEN, response.accessToken)
    sessionStorage.setItem(KEY_REFRESH_TOKEN, response.refreshToken)
}

async function renewTokens() {
    const refreshToken = sessionStorage.getItem(KEY_REFRESH_TOKEN)
    if (refreshToken != null) {
        const handler = new BaseTokenRequestHandler(new FetchRequestor())
        const request = new TokenRequest({
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            client_id: process.env.VUE_APP_OPENID_CLIENT_ID,
            refresh_token: refreshToken,
        })
        const response = await handler.performTokenRequest(await getServiceConfiguration(), request)
        sessionStorage.setItem(KEY_ACCESS_TOKEN, response.accessToken)
        sessionStorage.setItem(KEY_ID_TOKEN, response.idToken)
        sessionStorage.setItem(KEY_REFRESH_TOKEN, response.refreshToken)
    }
}

function isTokenExpired(jwtPayload) {
    return jwtPayload.exp <= Date.now() / 1000
}

async function getServiceConfiguration() {
    if (sessionStorage.getItem(KEY_ISSUER_CONFIG) == null) {
        const config = await AuthorizationServiceConfiguration.fetchFromIssuer(process.env.VUE_APP_OPENID_ISSUER, new FetchRequestor())
        sessionStorage.setItem(KEY_ISSUER_CONFIG, JSON.stringify(config.toJson()))
        return config
    } else {
        return new AuthorizationServiceConfiguration(JSON.parse(sessionStorage.getItem(KEY_ISSUER_CONFIG)))
    }
}
