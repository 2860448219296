<template>
  <div class="searchbar">
    <Searchbar></Searchbar>
  </div>
  <br>
  <div class="gefilter">
    <Modulauswahl :courses="selectCourses" :selected="Modulauswahl.value" @clicked="onChangeAuswahl"/>
    <div>
      <keep-alive>
        <Modulfilter v-if="Modulauswahl.value && selectCourses.includes(Modulauswahl.value) && !testEigEvent"
                     :key="Modulauswahl.value.course_identifier" :course="Modulauswahl.value"
                     @checked="onChangeFilter"></Modulfilter>
      </keep-alive>
      <div v-if="testEigEvent">
        <eigenes-event @close="closeEvent" @submitted="onSubmitOwnEvent"></eigenes-event>
      </div>
    </div>
  </div>
  <br>
  <br>
  <Timetable v-if="Events.length > 0" :events="Events" class="timetable"></Timetable>
</template>

<script>
import Timetable from "@/components/Timetable";
import Searchbar from "@/components/Searchbar";
import Modulfilter from "@/components/Modulfilter";
import {cterm, eigenesEvent, getCourseById, selectedCourses} from "@/courses";
import {computed, defineComponent, reactive} from "vue";
import Modulauswahl from "@/components/Modulauswahl";
import axios from "axios";
import auth from "@fsinfuhh/js_auth_mafiasi";
import EigenesEvent from "@/components/EigenesEvent";

export default defineComponent({
  name: 'Index',
  components: {
    EigenesEvent,
    Modulauswahl,
    Modulfilter,
    Searchbar,
    Timetable
  },
  setup() {
    const Modules = reactive({})
    const selectCourses = computed(() => selectedCourses.selected.map(id => getCourseById(id)))
    const Modulauswahl = reactive({})
    const OwnEvents = reactive([])
    return {
      Modules,
      selectCourses: selectCourses,
      Modulauswahl,
      OwnEvents,
      Events: computed(() => {
        return (Object
            .keys(Modules)
            .filter(id => selectCourses.value
                .map(course => course.course_identifier)
                .includes(id))
            .map(key => Modules[key])
            .flat()).concat(OwnEvents);
      })
    }
  },
  computed: {
    testEigEvent() {
      return eigenesEvent.value === true
    }
  },
  methods: {
    getEvents(course_id) {
      const init = async () => {
        const response = await axios.get('https://directory.mafiasi.de/api/v1/term/' + cterm.value.id + '/course_offer/' + course_id + '/event/?expand=groups,groups.appointments.rooms,appointments.rooms&omit=appointments.rooms.eventappointments,appointments.rooms.eventgroupappointments,groups.appointments.rooms.eventappointments,groups.appointments.rooms.eventgroupappointments', {
          headers: {Authorization: "Bearer " + await auth.getAccessToken()}
        })
        let course = getCourseById(course_id)
        if (typeof course.events[0] !== 'object') {
          course.events = response.data.results
        }
      }
      init()
    },
    onChangeFilter(key, value) {
      this.Modules[key] = value;
      if (value === undefined) {
        delete this.Modules[key];
      }
    },
    onChangeAuswahl(id) {
      let course = getCourseById(id);
      if (typeof course.events[0] !== 'object') {
        this.getEvents(id)
      }
      this.Modulauswahl.value = course;
    },
    onSubmitOwnEvent(events) {
      for (let e of events) {
        this.OwnEvents.push(e)
      }
      this.closeEvent()
    },
    closeEvent() {
      eigenesEvent.value = false
    }
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style scoped>
.searchbar {
  padding-left: 2em;
  padding-right: 2em;
}

.timetable {
  padding-right: 2em;
  padding-left: 1em;
  padding-bottom: 2em;
}

.modulfilter {
  display: grid;
  flex-wrap: wrap;
  gap: 3em;
  align-self: center;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(33em, 1fr));
  justify-items: center;
  align-items: center;
}

.gefilter {
  display: grid;
  grid-template-columns: 1fr 6fr;
  margin: 2em;
  grid-gap: 2em;
}
</style>
