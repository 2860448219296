<template>
  <div class="modulcard">
    <span class="titel">{{ course.course_identifier }} - {{ course.course_name }}</span>
    <dl v-if="typeof course.events[0] === 'object'">
      <dt>
        <input :id="'selectAll' + course.course_identifier" v-model='isCheckedAll'
               type="checkbox" @click="selectAll()"/>
        <label :for="'selectAll' + course.course_identifier"> Alles auswählen </label>
      </dt>
      <dt v-for="event in course.events" :key="event.name" class="überschrift">
        <div v-if="!event.groups.length" class="option">
          <input :id="'checkEvent' + course.course_identifier + '-' + event.name"
                 v-model="checkedEvents"
                 :value=event.name
                 type="checkbox"
                 @change="onClick"/>
          <label :for="'checkEvent' + course.course_identifier + '-' + event.name"
                 class="description-text">{{ event.name }}</label>
        </div>
        <div v-else class="überschrift">
          {{ event.name }}
        </div>
        <dl v-if="event.groups.length" class="optionlist">
          <dt v-for="group in reversed(event.groups)" :key="group.name">
            <div class="option">
              <input :id="'checkGroup' + course.course_identifier + '-' + group.name" v-model="checkedGroups"
                     :value="group.name" type="checkbox" @change="onClick"/>
              <label :for="'checkGroup' + course.course_identifier + '-' + group.name"
                     class="description-text">{{ group.name }}</label>
            </div>
          </dt>
        </dl>
      </dt>
    </dl>
    <div v-else class="loader">
      <socket></socket>
    </div>
  </div>
</template>

<script>
import {Socket} from 'vue-loading-spinner'
import moment from 'moment'
import { ref } from 'vue'

export default {
  name: "Modulfilter.vue",
  components: {
    Socket
  },
  data() {
    return {
      days: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ],
      checkedEvents: [],
      checkedGroups: [],
      isCheckedAll: ref(false),
      col: this.selectColour()
    }
  },
  methods: {
    reversed(input) {
      let copy = [...input];
      copy.reverse();
      return copy;
    },
    getSlot(date, time) {
      let d = new Date(date + "T" + time);
      return Math.floor((((d.getHours() - 7) * 60) + d.getMinutes()) / 5) + 1
    },
    getLength(date, start_time, end_time) {
      return this.getSlot(date, end_time) - this.getSlot(date, start_time)
    },
    selectColour() {
      let selection = [ 34,
        58,
        84,
        204,
        282,
        298,
        340,
        196 ];
      let num = Math.floor(Math.random() * selection.length)
      return selection[num]
    },
    getEvents(course) {
      let c = []
      for (let ev of course.events) {
        if (ev.groups.length === 0) {
          c.push(ev.appointments.map(appointment => {
            let date = new Date(appointment.day);
            let name = ev.name;
            let weekday = moment(date).weekday();
            let week = moment(date).week();
            let start = this.getSlot(appointment.day, appointment.start);
            let span = this.getLength(appointment.day, appointment.start, appointment.end);
            let end = start + span;
            let room = appointment.rooms.map(room => room.name).join(", ");
            let colour = "hsl(" + this.col + ", 90%, 45%)";
            return {name, weekday, week, start, end, span, room, colour};
          }))
        } else {
          for (let group of ev.groups) {
            c.push(group.appointments.map(appointment => {
              let date = new Date(appointment.day);
              let name = ev.name;
              let groupi = group.name;
              let weekday = moment(date).weekday();
              let week = moment(date).week();
              let start = this.getSlot(appointment.day, appointment.start);
              let span = this.getLength(appointment.day, appointment.start, appointment.end);
              let end = start + span;
              let room = appointment.rooms.map(room => room.name).join(", ");
              let colour = "hsl(" + this.col + ", 80%, 65%)";
              return {name, group: groupi, weekday, week, start, end, span, room, colour};
            }))
          }
        }
      }
      return c.flat()
    },
    onClick() {
      this.$emit('checked', this.course.course_identifier, this.displayedEvents);
      this.updateCheckAll()
    },
    selectAll() {
      this.isCheckedAll = !this.isCheckedAll;
      this.checkedGroups = [];
      this.checkedEvents = [];
      if (this.isCheckedAll) {
        for (let ev of this.course.events) {
          if (!ev.groups.length) {
            this.checkedEvents.push(ev.name)
          } else {
            for (let group of ev.groups) {
              this.checkedGroups.push(group.name)
            }
          }
        }
      }
      this.$emit('checked', this.course.course_identifier, this.displayedEvents)
    },
    updateCheckAll() {
      let checkedAll = true
      for (let ev of this.course.events) {
        if (!ev.groups.length) {
          if (!(this.checkedEvents.includes(ev.name))) {
            checkedAll = false
            break
          }
        } else {
          for (let group of ev.groups) {
            if (!(this.checkedGroups.includes(group.name))) {
              checkedAll = false
              break
            }
          }
        }
      }
      this.isCheckedAll = checkedAll
    },
  },
  props: {
    course: Object,
  },
  computed: {
    displayedEvents() {
      return this.getEvents(this.course).filter(event => this.checkedGroups.includes(event.group) || this.checkedEvents.includes(event.name));
    }
  },
  beforeUnmount() {
    this.$emit('checked', this.course.course_identifier, undefined)
  }
}
</script>

<style scoped>
.modulcard {
  text-align: initial;
  width: 100%;
  box-shadow: 5px 5px 10px 5px #adbac6;
  padding: 16px;
  border-radius: 1em;
}

.modulcard .option {
  display: flex;
  font-weight: initial;
  width: 33em;
}

.modulcard .description-text {
  padding-left: 0.8em;
}

.modulcard .überschrift {
  margin: 16px;
  margin-left: 0;
  font-weight: 600;
}

.modulcard .titel {
  font-weight: bolder;
  font-size: large;
}

.optionlist {
  display: flex;
  flex-wrap: wrap;
}

.loader {
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  margin-bottom: 1em;
  margin-top: 2em;
}
</style>
