<template>
  <div class="selecting">
    <button @click="WeekBefore" class="s"> &lt;</button>
    <label class="week-selector">
      KW:
      <select v-model="selectedWeek">
        <option disabled></option>
        <option v-for="i in weeks" :key=i>{{ i }}</option>
      </select>
    </label>
    <button @click="WeekAfter" class="s"> &gt;</button>
  </div>
  <div class="week">
    <div></div>
    <div v-for="day in daysWithDates" :key="day" class="capitalize">
      <b>{{ day.format("dddd, LL") }}</b>
    </div>
    <div class="times">
      <div v-for="n in 60" :key="n" :style='"grid-row:" + (n*3-2) + "/ span 3"'>
        {{ time(n) }}
      </div>
    </div>
    <Day v-for="day in daysWithDates" :key="day"
         :events="events.filter(e => e.weekday===day.weekday() && e.week===parseInt(selectedWeek))"></Day>
  </div>
</template>

<script>
import Day from "@/components/Day";
import {ref} from "vue";
import moment from "moment";
import {cterm} from "@/courses";

export default {
  name: "Timetable.vue",
  components: {
    Day
  },
  setup() {
    return {
      weeks: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52
      ],
      selectedWeek: ref(14), // Sommersemester
    }
  },
  methods: {
    time(n) {
      return (7 + Math.floor((n - 1) / 4) + ":" + ("00" + ((n - 1) % 4 * 15)).slice(-2))
    },
    selectFirstWeek() {
      if (cterm.value.type === 1) {
        return ref(14)
      } else {
        return ref(44)
      }
    },
    WeekBefore() {
      this.selectedWeek = parseInt(this.selectedWeek) - 1
    },
    WeekAfter() {
      this.selectedWeek = parseInt(this.selectedWeek) + 1
    }
  },
  props: {
    events: Array
  },
  computed: {
    daysWithDates() {
      const day = moment().day("Montag").week(this.selectedWeek)
      return [
        moment(day),
        moment(day).add(1, "day"),
        moment(day).add(2, "day"),
        moment(day).add(3, "day"),
        moment(day).add(4, "day"),
      ]
    }
  }
}
</script>

<style scoped>
.week {
  display: grid;
  grid-template-columns: 7em 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 35px max-content;
}

.times {
  display: grid;
  grid-template-rows: repeat(180, 6px);
  grid-template-columns: 1fr;
}

.capitalize {
  text-transform: capitalize;
}

.week-selector {
  padding-left: 1em;
  padding-right: 1em;
}

.selecting {
  margin-bottom: 2em;
}

.s {
  border: none;
  background-color: unset;
  font-size: larger;
  font-weight: bolder;
  text-shadow: 4px 4px 3px #686868;;
}

.s:active {
  text-shadow: unset;
  translate: 2px 2px;
}
</style>