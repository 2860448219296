<template>
  <div>You will automatically be redirected</div>
</template>

<script>
import {defineComponent, onMounted} from 'vue';
import auth from "@fsinfuhh/js_auth_mafiasi";
import {useRouter} from "vue-router";
import {client} from "@/main"

export default defineComponent({
  setup() {
    const router = useRouter()
    onMounted(() => {
      const redirectUri = `${window.location.origin}${router.resolve({name: "Callback"}).href}`
      const OpenidConfig = {
        issuer: "https://identity.mafiasi.de/realms/mafiasi",
        clientId: client
      }
      auth.doAuthorization(redirectUri, OpenidConfig).then(() => {
      })

    })
  }
})
</script>
