<template>
  <div class="day">
    <span  class="lines"
           v-for="n in 60"
           :key="n" :style='"grid-row:" + (n*3-2) + "/ span 3; grid-column: 1 / " + (columns.length+1)'>
    </span>
    <span class="event cooltipz--top"
          v-for="event in columns.flat()"
          :key="event"
          data-html="true"
          data-cooltipz-size="medium"
          :aria-label="event.name + ', ' + (event.group ? event.group + ',' : '') + ' ' + 'Raum: ' + event.room"
          :style='"grid-row:" + event.start + " / span " + event.span + "; grid-column: " + event.column + "; background-color: " + event.colour'>
      <span class="event-text">{{event.name}} <br>
      <span v-if="event.group">{{event.group}} <br> </span>
      Raum: {{event.room}} </span>
    </span>
  </div>
</template>

<script>

export default {
  name: "Day.vue",
  props: {
    events: Array
  },
  computed: {
    columns() {
      let cols = [];
      for (let event of this.events) {
        let sorted = false;
        for (let [i, col] of cols.entries()) {
          let collision = false;
          for (let other of col) {
            if (!(event.start >= other.end || other.start >= event.end)) {
              collision = true;
              break;
            }
          }
          if (!collision) {
            sorted = true;
            cols[i].push(event);
            break;
          }
        }
        if (!sorted) {
          cols.push([event])
        }
      }
      for (let [i, col] of cols.entries()){
        // eslint-disable-next-line no-unused-vars
        for (let [j, event] of col.entries()) {
          cols[i][j].column = i + 1;
        }
      }
      return cols;
    }
  }
}
</script>

<style scoped>
.day {
  display: grid;
  grid-template-rows: repeat(180, 6px);
  grid-auto-columns: 1fr;
}
.lines {
  border: solid 1px;
  color: darkgrey;
}
.event {
  grid-column: 1 / 1;
  border: solid 1px;
  text-align: center;
}

.event-text {
  overflow: hidden;
  text-overflow: ellipsis; 
  display: inline-block;
  height: 100%;
}
</style>
