import {createApp} from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import 'spectre.css/dist/spectre.min.css'
import 'cooltipz-css/cooltipz.min.css'
import Index from '@/views/Index.vue'
import Login from '@/views/Login.vue'
import Callback from '@/views/Callback.vue'
import auth from "@fsinfuhh/js_auth_mafiasi"
import moment from "moment"

let client;
if (process.env.NODE_ENV === "production") {
    client = "mafiasi-stundenplaner"
}
else {
    client = "dev-client"
}
export { client }
moment.locale("de")

const app = createApp(App)
const routes = [
    {name: "Index", path:'/', component: Index, async beforeEnter(to, from, next){
            if (!(await auth.isAuthenticated())) {
                next({ "name": "Login" })
            } else {
                next()
            }
        }
    },
    {name: "Login", path:'/login', component:Login},
    {name: "Callback", path:'/callback', component: Callback}]
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for routes: routes
})
app.use(router)
app.mount('#app')