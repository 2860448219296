<template>
  <div>
    <Multiselect
        v-model="selectedCourses.selected"
        placeholder="Wähle deine Module"
        mode="tags"
        :options="options"
        :searchable="true"
        trackBy="name"
        label="id"
        noOptionsText="Module laden..."
    >
      <template v-slot:singleLabel="{ value }">
        <div class="multiselect-single-label">
          {{ value.name }}
        </div>
      </template>

      <template v-slot:option="{ option }">
        {{ option.name }}
      </template>
    </Multiselect>
  </div>
  <br>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { courses, selectedCourses, cterm } from "@/courses"
import Multiselect from "@vueform/multiselect"
import auth from "@/auth";

export default defineComponent({
name: "Searchbar",
  components: {
    Multiselect
  },
  setup() {
    const init = async () => {
      const response = await axios.get('https://directory.mafiasi.de/api/v1/term/', {
        headers: {Authorization: "Bearer " + await auth.getAccessToken()}
      })
      let term = response.data.results.reduce((term1, term2) => {
        if (term1.year > term2.year && term1.course_offers.length) {
          return term1
        }
        if (term2.year > term1.year && term2.course_offers.length) {
          return term2
        }
        if (term1.term_type > term2.term_type && term1.course_offers.length) {
          return term1
        }
        if (term2.course_offers.length) {
          return term2
        }
      })
      cterm.value = {id: term.id, type: term.term_type}
      const response2 = await axios.get('https://directory.mafiasi.de/api/v1/term/' + term.id + '/course_offer/', {
        headers: {Authorization: "Bearer " + await auth.getAccessToken()}
      })
      courses.push(...response2.data.results)
    }
    init()
    return {
      selectedCourses,
      courses
    }
  },
  computed: {
    options() {
      return courses.map(course => {
        let name = course.course_identifier + ' ' + course.course_name
        return ({value: course.id, name, id: course.course_identifier})
      }
        );
    }
  }
})
</script>

<style scoped>

</style>

<style src="@vueform/multiselect/themes/default.css"></style>
